import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Icon, UilChartPie } from '@iconscout/react-unicons';

export interface Route {
  name: string;
  icon?: IconProp | string | string[];
  iconSet?: 'font-awesome' | 'feather' | 'unicons';
  pages?: Route[];
  path?: string;
  pathName?: string;
  flat?: boolean;
  topNavIcon?: string;
  dropdownInside?: boolean;
  active?: boolean;
  new?: boolean;
  hasNew?: boolean;
}

export interface RouteItems {
  label: string;
  horizontalNavLabel?: string;
  icon: Icon;
  labelDisabled?: boolean;
  pages: Route[];
  megaMenu?: boolean;
  active?: boolean;
}

export const routes: RouteItems[] = [
  {
    label: 'directories',
    horizontalNavLabel: 'home',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'directories',
        icon: 'book-open',
        active: true,
        flat: true,
        pages: [
          {
            name: 'languages',
            path: '/',
            pathName: 'default-directories',
            topNavIcon: 'shopping-cart',
            active: true
          },

          {
            name: 'country',
            path: '/directories/country',
            pathName: 'country-directories',
            topNavIcon: 'clipboard',
            active: true
          },
          {
            name: 'region',
            path: '/directories/region',
            pathName: 'region-directories',
            topNavIcon: 'clipboard',
            active: true
          },
          {
            name: 'area',
            path: '/directories/area',
            pathName: 'area-directories',
            topNavIcon: 'clipboard',
            active: true
          },
          {
            name: 'currency',
            path: '/directories/currency',
            pathName: 'currency-directories',
            topNavIcon: 'clipboard',
            active: true
          },
          {
            name: 'measure_unit_type',
            path: '/directories/measurement',
            pathName: 'measurement-directories',
            topNavIcon: 'clipboard',
            active: true
          },
          {
            name: 'measure_unit',
            path: '/directories/units',
            pathName: 'units-directories',
            topNavIcon: 'clipboard',
            active: true
          },
          {
            name: 'variation',
            path: '/directories/variation',
            pathName: 'variation-directories',
            topNavIcon: 'clipboard',
            active: true
          },
          {
            name: 'characteristic',
            path: '/directories/characteristic',
            pathName: 'characteristic-directories',
            topNavIcon: 'clipboard',
            active: true
          },
          {
            name: 'type_activity',
            path: '/directories/activity',
            pathName: 'activity-directories',
            topNavIcon: 'clipboard',
            active: true
          },
          {
            name: 'category',
            path: '/directories/category',
            pathName: 'category-directories',
            topNavIcon: 'clipboard',
            active: true
          },
          {
            name: 'product_type',
            path: '/directories/product',
            pathName: 'product-directories',
            topNavIcon: 'clipboard',
            active: true
          },
          {
            name: 'product_status',
            path: '/directories/state',
            pathName: 'state-directories',
            topNavIcon: 'clipboard',
            active: true
          },
          {
            name: 'calculation_type',
            path: '/directories/calculation',
            pathName: 'calculation-directories',
            topNavIcon: 'clipboard',
            active: true
          },
          {
            name: 'payment_type',
            path: '/directories/payment',
            pathName: 'payment-directories',
            topNavIcon: 'clipboard',
            active: true
          },
          {
            name: 'payment_assignments',
            path: '/directories/purpose',
            pathName: 'purpose-directories',
            topNavIcon: 'clipboard',
            active: true
          },
          {
            name: 'payment_procedure',
            path: '/directories/procedure',
            pathName: 'procedure-directories',
            topNavIcon: 'clipboard',
            active: true
          },
          {
            name: 'vat',
            path: '/directories/nds',
            pathName: 'nds-directories',
            topNavIcon: 'clipboard',
            active: true
          },
          {
            name: 'users_type',
            path: '/directories/userstype',
            pathName: 'userstype-directories',
            topNavIcon: 'clipboard',
            active: true
          },
          {
            name: 'legal_entity_status',
            path: '/directories/entities',
            pathName: 'entities-directories',
            topNavIcon: 'clipboard',
            active: true
          },
          {
            name: 'legal_entity_accesses',
            path: '/directories/accesses',
            pathName: 'accesses-directories',
            topNavIcon: 'clipboard',
            active: true
          },
          // {
          //   name: 'Пол',
          //   path: '/directories/gender',
          //   pathName: 'gender-directories',
          //   topNavIcon: 'clipboard',
          //   active: true
          // },
          {
            name: 'evaluation_method',
            path: '/directories/method',
            pathName: 'method-directories',
            topNavIcon: 'clipboard',
            active: true
          },
          {
            name: 'time_zone',
            path: '/directories/timezone',
            pathName: 'timezone-directories',
            topNavIcon: 'clipboard',
            active: true
          },
          // {
          //   name: 'Виды пополнения баланса',
          //   path: '/directories/replenishment',
          //   pathName: 'replenishment-directories',
          //   topNavIcon: 'clipboard',
          //   active: true
          // },
          {
            name: 'purchase_type',
            path: '/directories/purchase',
            pathName: 'purchase-directories',
            topNavIcon: 'clipboard',
            active: true
          },
          {
            name: 'reason_for_returning',
            path: '/directories/returning',
            pathName: 'returning-directories',
            topNavIcon: 'clipboard',
            active: true
          },
          {
            name: 'document_status',
            path: '/directories/documents',
            pathName: 'documents-directories',
            topNavIcon: 'clipboard',
            active: true
          },
          {
            name: 'inventory_status',
            path: '/directories/inventory',
            pathName: 'inventory-directories',
            topNavIcon: 'clipboard',
            active: true
          },
          {
            name: 'finance',
            active: true,
            pages: [
              {
                name: 'account',
                path: '/directories/finance/accounts',
                pathName: 'accounts-finance-directories',
                topNavIcon: 'clipboard',
                active: true
              },
              {
                name: 'article',
                path: '/directories/finance/articles',
                pathName: 'articles-finance-directories',
                active: true
              }
            ]
          },
          // {
          //   name: 'Бонусы',
          //   active: true,
          //   pages: [
          //     {
          //       name: 'Область применения',
          //       path: '/directories/bonuses/application',
          //       pathName: 'application-bonuses-directories',
          //       topNavIcon: 'clipboard',
          //       active: true
          //     },
          //     {
          //       name: 'Активация бонуса',
          //       path: '/directories/bonuses/activation',
          //       pathName: 'activation-bonuses-directories',
          //       topNavIcon: 'clipboard',
          //       active: true
          //     }
          //     // {
          //     //   name: 'Способ оплаты',
          //     //   path: '/directories/bonuses/payment',
          //     //   pathName: 'payment-bonuses-directories',
          //     //   topNavIcon: 'clipboard',
          //     //   active: true
          //     // }
          //   ]
          // },
          {
            name: 'source_consumption',
            path: '/directories/expense-source',
            pathName: 'expense-source-directories',
            topNavIcon: 'clipboard',
            active: true
          },
          {
            name: 'bonus_activation',
            path: '/directories/bonus-activation',
            pathName: 'bonus-activation-directories',
            active: true
          },
          {
            name: 'bonus_application',
            path: '/directories/bonus-application',
            pathName: 'bonus-application-directories',
            active: true
          },
          {
            name: 'bonus_payment',
            path: '/directories/bonus-payment',
            pathName: 'bonus-payment-directories',
            active: true
          },
          {
            name: 'degree_rate',
            path: '/directories/rate-degree',
            pathName: 'rate-degree-directories',
            active: true
          },
          // {
          //   name: 'Субъекты права',
          //   path: '/directories/subjects',
          //   pathName: 'subjects-directories',
          //   topNavIcon: 'clipboard',
          //   active: true
          // },
          {
            name: 'desktop_version',
            path: '/directories/desktop-version',
            pathName: 'desktop-version-directories',
            topNavIcon: 'clipboard',
            active: true
          },
          {
            name: 'errors',
            path: '/directories/errors',
            pathName: 'errors-directories',
            topNavIcon: 'clipboard',
            active: true
          }
        ]
      }
    ]
  },
  {
    label: 'nomenclature',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'nomenclature',
        icon: 'tag',
        path: '/nomenclature',
        pathName: 'nomenclature',
        active: true
      }
    ]
  },
  {
    label: 'holding',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'holding',
        icon: 'sunrise',
        path: '/holding',
        pathName: 'holding',
        active: true
      }
    ]
  },

  {
    label: 'legal',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'legal_entities',
        icon: 'briefcase',
        path: '/legal-entity',
        pathName: 'legal-entity',
        active: true
      }
    ]
  },

  {
    label: 'brand',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'brands',
        icon: 'watch',
        path: '/brand',
        pathName: 'brand',
        active: true
      }
    ]
  },
  {
    label: 'users',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'users',
        icon: 'users',
        path: '/users',
        pathName: 'users',
        active: true
      }
    ]
  },
  {
    label: 'bonus',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'bonuses',
        icon: 'award',
        path: '/bonus',
        pathName: 'bonus',
        active: true
      }
    ]
  }
];
