/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const legalEntityService = {
  getList: (params: any) => request.get('/legal-entity', { params }),
  getListSystem: (params: any) =>
    request.get('/system/legal-entity', { params }),
  getLegalEntityById: (id: any) => request.get(`/legal-entity/${id}`),
  createLegalEntity: (data: any) => request.post('/legal-entity', data),
  deleteLegalEntity: (id: any) => request.delete(`/legal-entity/${id}`),
  updateLegalEntity: (id: any, data: any) =>
    request.put(`/legal-entity/${id}`, data)
};

export default legalEntityService;
