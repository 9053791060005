import MainLayout from 'layouts/MainLayout';
import { RouteObject, createBrowserRouter } from 'react-router-dom';
import MainLayoutProvider from 'providers/MainLayoutProvider';
import App from 'App';

//RIZO COMPONENTS
import Languages from 'pages/directories/languages';

import Country from 'pages/directories/country';

import Region from 'pages/directories/region';

import Area from 'pages/directories/area';

import Currency from 'pages/directories/currency';

import Measurement from 'pages/directories/measurement';

import UnitsOfMeasurement from 'pages/directories/units';

import Variation from 'pages/directories/variation';

import Characteristic from 'pages/directories/characteristic';

import Activity from 'pages/directories/activity';

import Category from 'pages/directories/category';

import Product from 'pages/directories/product';

import StateOfProduct from 'pages/directories/state';

import TypeOfCalculation from 'pages/directories/calculation';

import TypeOfPayment from 'pages/directories/payment';

import PaymentPurposes from 'pages/directories/purpose';

import PaymentProcedure from 'pages/directories/procedure';

import Nds from 'pages/directories/nds';

import UsersType from 'pages/directories/userstype';

import LegalEntities from 'pages/directories/entities';

import LegalAccesses from 'pages/directories/accesses';

import Gender from 'pages/directories/gender';
import CreateGender from 'pages/directories/gender/CreateGender';

import Method from 'pages/directories/method';

import TimeZone from 'pages/directories/timezone';

import Replenishment from 'pages/directories/replenishment';
import CreateReplenishment from 'pages/directories/replenishment/CreateReplenishment';

import Purchase from 'pages/directories/purchase';

import Returning from 'pages/directories/returning';

import Documents from 'pages/directories/documents';

import Inventory from 'pages/directories/inventory';

import Subjects from 'pages/directories/subjects';
import CreateSubjects from 'pages/directories/subjects/CreateSubjects';

import Accounts from 'pages/directories/finance/accounts';

import Articles from 'pages/directories/finance/articles';

import Application from 'pages/directories/bonuses/application';
import CreateApplication from 'pages/directories/bonuses/application/CreateApplication';

import Activation from 'pages/directories/bonuses/activation';
import CreateActivation from 'pages/directories/bonuses/activation/CreateActivation';

import PaymentMethod from 'pages/directories/bonuses/payment';
import CreatePaymentMethod from 'pages/directories/bonuses/payment/CreatePaymentMethod';

import Holding from 'pages/holding';

import Nomenclature from 'pages/nomenclature';

import Brand from 'pages/brand';

import SignIn from 'pages/auth/SignIn';
import Users from 'pages/users';
import ExpensesSource from 'pages/directories/expensesSource';
import Bonus from 'pages/bonus';
import BonusActivation from 'pages/directories/bonusActivation';
import BonusApplication from 'pages/directories/bonusApplication';
import BonusPayment from 'pages/directories/bonusPayment';
import RateDegree from 'pages/directories/rateDegree';
import DesktopVersion from 'pages/directories/desktopVersion';
import Errors from 'pages/directories/errors';
import LegalEntity from 'pages/legalEntity';

const routes: RouteObject[] = [
  {
    element: <App />,
    children: [
      {
        path: '/',
        element: (
          <MainLayoutProvider>
            <MainLayout />
          </MainLayoutProvider>
        ),
        children: [
          {
            index: true,
            element: <Languages />
          },
          {
            path: '/directories',
            children: [
              {
                path: 'country',
                element: <Country />
              },

              {
                path: 'region',
                element: <Region />
              },

              {
                path: 'area',
                element: <Area />
              },

              {
                path: 'currency',
                element: <Currency />
              },

              {
                path: 'measurement',
                element: <Measurement />
              },

              {
                path: 'units',
                element: <UnitsOfMeasurement />
              },

              {
                path: 'variation',
                element: <Variation />
              },

              {
                path: 'characteristic',
                element: <Characteristic />
              },

              {
                path: 'activity',
                element: <Activity />
              },

              {
                path: 'category',
                element: <Category />
              },

              {
                path: 'product',
                element: <Product />
              },

              {
                path: 'state',
                element: <StateOfProduct />
              },

              {
                path: 'calculation',
                element: <TypeOfCalculation />
              },

              {
                path: 'payment',
                element: <TypeOfPayment />
              },

              {
                path: 'purpose',
                element: <PaymentPurposes />
              },

              {
                path: 'procedure',
                element: <PaymentProcedure />
              },

              {
                path: 'nds',
                element: <Nds />
              },

              {
                path: 'userstype',
                element: <UsersType />
              },

              {
                path: 'entities',
                element: <LegalEntities />
              },

              {
                path: 'accesses',
                element: <LegalAccesses />
              },

              {
                path: 'gender',
                element: <Gender />
              },
              {
                path: 'gender/create-gender',
                element: <CreateGender />
              },
              {
                path: 'gender/create-gender/:genderId',
                element: <CreateGender />
              },

              {
                path: 'method',
                element: <Method />
              },

              {
                path: 'timezone',
                element: <TimeZone />
              },

              {
                path: 'replenishment',
                element: <Replenishment />
              },
              {
                path: 'replenishment/create-replenishment',
                element: <CreateReplenishment />
              },
              {
                path: 'replenishment/create-replenishment/:replenishmentId',
                element: <CreateReplenishment />
              },

              {
                path: 'purchase',
                element: <Purchase />
              },

              {
                path: 'returning',
                element: <Returning />
              },

              {
                path: 'documents',
                element: <Documents />
              },

              {
                path: 'inventory',
                element: <Inventory />
              },

              {
                path: '/directories/finance',
                children: [
                  {
                    path: 'accounts',
                    element: <Accounts />
                  },

                  {
                    path: 'articles',
                    element: <Articles />
                  }
                ]
              },
              {
                path: '/directories/bonuses',
                children: [
                  {
                    path: 'application',
                    element: <Application />
                  },
                  {
                    path: 'application/create-application',
                    element: <CreateApplication />
                  },
                  {
                    path: 'application/create-application/:applicationId',
                    element: <CreateApplication />
                  },

                  {
                    path: 'activation',
                    element: <Activation />
                  },
                  {
                    path: 'activation/create-activation',
                    element: <CreateActivation />
                  },
                  {
                    path: 'activation/create-activation/:activationId',
                    element: <CreateActivation />
                  },

                  {
                    path: 'payment',
                    element: <PaymentMethod />
                  },
                  {
                    path: 'payment/create-payment',
                    element: <CreatePaymentMethod />
                  },
                  {
                    path: 'payment/create-payment/:paymentId',
                    element: <CreatePaymentMethod />
                  }
                ]
              },

              {
                path: 'expense-source',
                element: <ExpensesSource />
              },

              {
                path: 'subjects',
                element: <Subjects />
              },
              {
                path: 'subjects/create-subjects',
                element: <CreateSubjects />
              },
              {
                path: 'subjects/create-subjects/:subjectsId',
                element: <CreateSubjects />
              },
              {
                path: 'bonus-activation',
                element: <BonusActivation />
              },
              {
                path: 'bonus-application',
                element: <BonusApplication />
              },
              {
                path: 'bonus-payment',
                element: <BonusPayment />
              },
              {
                path: 'rate-degree',
                element: <RateDegree />
              },
              {
                path: 'desktop-version',
                element: <DesktopVersion />
              },
              {
                path: 'errors',
                element: <Errors />
              }
            ]
          },
          {
            path: '/nomenclature',
            children: [
              {
                index: true,
                element: <Nomenclature />
              }
            ]
          },
          {
            path: '/holding',
            children: [
              {
                index: true,
                element: <Holding />
              }
            ]
          },
          {
            path: '/legal-entity',
            children: [
              {
                index: true,
                element: <LegalEntity />
              }
            ]
          },
          {
            path: '/brand',
            children: [
              {
                index: true,
                element: <Brand />
              }
            ]
          },
          {
            path: '/users',
            children: [
              {
                index: true,
                element: <Users />
              }
            ]
          },
          {
            path: '/bonus',
            children: [
              {
                index: true,
                element: <Bonus />
              }
            ]
          }
        ]
      },
      {
        path: '/signin',
        element: <SignIn />
      }
    ]
  }
];

export const router = createBrowserRouter(routes);

export default routes;
