/* eslint-disable @typescript-eslint/no-explicit-any */

import { Form, Row, Col } from 'react-bootstrap';
import {
  useForm,
  FieldValues,
  SubmitHandler,
  Controller
} from 'react-hook-form';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';
import { showAlert } from 'store/alert/alert.thunk';
import languageService from 'services/language';
import paymentMethodService from 'services/paymentMethod';
import paymentTypeService from 'services/paymentType';
import countryService from 'services/country';
import currencyService from 'services/currency';
import ImageUpload from 'components/forms/ImageUpload';

interface CreateTypeOfPaymentProps {
  setOpenPayment: (show: boolean) => void;
  selectedPaymentId?: any;
  refetch?: any;
}

const CreateTypeOfPayment = ({
  setOpenPayment,
  selectedPaymentId,
  refetch
}: CreateTypeOfPaymentProps) => {
  const { t, i18n } = useTranslation();
  const posOption = [
    {
      value: true,
      label: t('yes')
    },
    {
      value: false,
      label: t('no')
    }
  ];

  const options = [
    {
      value: true,
      label: t('active')
    },
    {
      value: false,
      label: t('blocked')
    }
  ];
  const [loading, setLoading] = useState(false);
  const [typeOption, setTypeOption] = useState([]);
  const [countryOption, setCountryOption] = useState([]);
  const [currencyOption, setCurrencyOption] = useState([]);

  const { register, handleSubmit, control, reset } = useForm();

  const dispatch: Dispatch<any> = useDispatch();

  //GET Lang
  const { data: langData } = useQuery(['GET_LANGUAGE'], () => {
    const res = languageService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => res?.languages);
    return res;
  });

  //GET Type to options
  useQuery(['GET_PAYMENT_TYPE'], async () => {
    await paymentTypeService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => {
        const options = res?.payment_types?.map((type: any) => ({
          value: type.id,
          label: type.name?.[i18n?.language]
        }));
        setTypeOption(options);
      });
  });

  //GET Country to options
  useQuery(['GET_COUNTRY'], async () => {
    await countryService.getList({ offset: 0, limit: 100 }).then((res: any) => {
      const options = res?.countries?.map((country: any) => ({
        value: country.id,
        label: country.name?.[i18n?.language]
      }));
      setCountryOption(options);
    });
  });

  //GET Currency to options
  useQuery(['GET_CURRENCY'], async () => {
    await currencyService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => {
        const options = res?.currencies?.map((currency: any) => ({
          value: currency.id,
          label: currency.name?.[i18n?.language]
        }));
        setCurrencyOption(options);
      });
  });

  //Get by id
  const fetchData = () => {
    if (selectedPaymentId === '') return setLoading(false);

    paymentMethodService
      .getPaymentMethodById(selectedPaymentId)
      .then((res: any) => {
        const nameObj: any = {};
        Object.keys(res.name).forEach(lang => {
          nameObj[`name_${lang}`] = res.name[lang];
        });

        const computed = {
          ...nameObj,
          country: countryOption.find(
            (option: any) => option.value === res?.country_id
          ),
          count: typeOption.find(
            (option: any) => option.value === res?.payment_type_id
          ),
          units: currencyOption.find(
            (option: any) => option.value === res?.currency_id
          ),
          show: res?.use_on_pos_sale
            ? posOption.find(option => option.value === res?.use_on_pos_sale)
            : { label: 'Нет', value: false },
          status: options.find(option => option.value === res?.status),
          photo: process.env.REACT_APP_CDN_URL + res.photo
        };
        reset(computed);
      })
      .finally(() => setLoading(false));
  };

  const onSubmit: SubmitHandler<FieldValues> = data => {
    if (selectedPaymentId !== '') return update(data);
    createData(data);
  };

  const createData = (data: any) => {
    setLoading(true);

    const dynamicKeys = Object.keys(data).filter(key =>
      key.startsWith('name_')
    );
    const nameObj: any = {};

    dynamicKeys.forEach(key => {
      const lang = key.split('_')[1];
      nameObj[lang] = data[key];
    });

    const createdData = {
      name: nameObj,
      country_id: data?.country.value,
      currency_id: data?.units?.value,
      payment_type_id: data?.count?.value,
      use_on_pos_sale: data?.show?.value,
      status: data?.status.value,
      photo: data?.photo
        ? data.photo.substring(data.photo.indexOf('/photos/'))
        : ''
    };

    paymentMethodService
      .createPaymentMethod(createdData)
      .then((res: any) => {
        if (res) {
          dispatch(
            showAlert({
              title: 'Тип расчета измерения успешно добавлен!',
              type: 'success'
            })
          );
        }
      })
      .finally(() => {
        refetch();
        setOpenPayment(false);
        setLoading(false);
      });
  };

  const update = (data: any) => {
    setLoading(true);

    const dynamicKeys = Object.keys(data).filter(key =>
      key.startsWith('name_')
    );
    const nameObj: any = {};

    dynamicKeys.forEach(key => {
      const lang = key.split('_')[1];
      nameObj[lang] = data[key];
    });

    const updateData = {
      name: nameObj,
      country_id: data?.country.value,
      payment_type_id: data?.count?.value,
      currency_id: data?.units?.value,
      use_on_pos_sale: data?.show?.value,
      status: data?.status.value,
      photo: data?.photo
        ? data.photo.substring(data.photo.indexOf('/photos/'))
        : '',
      id: selectedPaymentId
    };
    paymentMethodService
      .updatePaymentMethod(selectedPaymentId, updateData)
      .finally(() => {
        refetch();
        setLoading(false);
        setOpenPayment(false);
      });
  };

  useEffect(() => {
    if (selectedPaymentId !== '') {
      fetchData();
    }
  }, [langData, typeOption, countryOption, currencyOption, selectedPaymentId]);

  return (
    <div>
      <Form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        className="bg-white rounded"
      >
        {langData &&
          langData.map((item: any) => (
            <Row key={item.id} className="mb-3">
              <Col>
                <Form.Group className="md-4">
                  <Form.Label>
                    {t('name_payment')} ( {item.char_code} )
                  </Form.Label>
                  <Form.Control
                    {...register(`name_${item.char_code.toLowerCase()}`)}
                    type="text"
                    placeholder={`${t('name_payment')} ${item.char_code}`}
                  />
                </Form.Group>
              </Col>
            </Row>
          ))}

        <Row className="mb-3">
          <Col>
            <Form.Label>{t('calculation_type')}</Form.Label>
            <div className="react-select-container">
              <Controller
                name="count"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    closeMenuOnSelect={true}
                    classNamePrefix="react-select"
                    placeholder={t('select')}
                    options={typeOption}
                    styles={{
                      control: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      singleValue: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      menuPortal: base => ({
                        ...base,
                        fontSize: '12.8px'
                      })
                    }}
                  />
                )}
              />
            </div>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Label>{t('country')}</Form.Label>
            <div className="react-select-container">
              <Controller
                name="country"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    closeMenuOnSelect={true}
                    classNamePrefix="react-select"
                    placeholder={t('select')}
                    options={countryOption}
                    styles={{
                      control: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      singleValue: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      menuPortal: base => ({
                        ...base,
                        fontSize: '12.8px'
                      })
                    }}
                  />
                )}
              />
            </div>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Label>{t('currency')}</Form.Label>
            <div className="react-select-container">
              <Controller
                name="units"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    closeMenuOnSelect={true}
                    classNamePrefix="react-select"
                    placeholder={t('select')}
                    options={currencyOption}
                    styles={{
                      control: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      singleValue: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      menuPortal: base => ({
                        ...base,
                        fontSize: '12.8px'
                      })
                    }}
                  />
                )}
              />
            </div>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Label>{t('show_in_pos')}</Form.Label>
            <div className="react-select-container">
              <Controller
                name="show"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    closeMenuOnSelect={true}
                    classNamePrefix="react-select"
                    placeholder={t('select')}
                    options={posOption}
                    styles={{
                      control: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      singleValue: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      menuPortal: base => ({
                        ...base,
                        fontSize: '12.8px'
                      })
                    }}
                  />
                )}
              />
            </div>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Form.Label>{t('status')}</Form.Label>
            <div className="react-select-container">
              <Controller
                name="status"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    closeMenuOnSelect={true}
                    classNamePrefix="react-select"
                    placeholder={t('select')}
                    options={options}
                    styles={{
                      control: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      singleValue: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      menuPortal: base => ({
                        ...base,
                        fontSize: '12.8px'
                      })
                    }}
                  />
                )}
              />
            </div>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Label>{t('photo')}</Form.Label>
            <Controller
              name="photo"
              control={control}
              defaultValue=""
              render={({ field }) => <ImageUpload {...field} />}
            />
          </Col>
        </Row>

        <Button
          className="w-100"
          variant="primary"
          type="submit"
          loading={loading}
        >
          {t('add')}
        </Button>
      </Form>
    </div>
  );
};

export default CreateTypeOfPayment;
