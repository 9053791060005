/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef } from '@tanstack/react-table';
import { useState, useMemo, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import Flatpickr from 'react-flatpickr';
import classNames from 'classnames';
import { Russian } from 'flatpickr/dist/l10n/ru.js';
import { UzbekLatin } from 'flatpickr/dist/l10n/uz_latn.js';

import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableCPagination from 'components/base/AdvanceTableCPagination';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import useAdvanceTable from 'hooks/useAdvanceTable';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import legalEntityService from 'services/legalEntity';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import { pageToOffset } from 'utils/pageToOffset';
import SearchBox from 'components/common/SearchBox';
import activityService from 'services/activity';

export const legalEntityBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'legal_entities',
    active: true
  }
];

export type LegalEntityType = {
  physical_name: string;
  type_physical_name: string;
  kinds: string;
  def_currency: string;
  country: string;
  region: string;
  area: string;
  address: string;
  phone: string;
  status: string;
  guid: string;
  block: boolean;
  logo_url: string;
  next_payment_date: string;
};

const LegalEntity = () => {
  const { t, i18n } = useTranslation();

  const customOption = [
    {
      label: t('yes'),
      value: 1
    },
    {
      label: t('no'),
      value: -1
    },
    {
      label: t('all'),
      value: 0
    }
  ];

  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [name, setName] = useState('');
  const [activityOption, setActivityOption] = useState<any>([]);
  const [selectedActivityId, setSelectedActivityId] = useState('');
  // const [selectedIsCustomValue, setSelectedIsCustomValue] = useState(0);
  const [selectedIsBlockedValue, setSelectedIsBlockedValue] = useState(0);

  //GET Payment Order Entity to options
  useQuery(['GET_ACTIVITY_KIND'], async () => {
    await activityService
      .getList({ offset: 0, limit: 100, status: true })
      .then((res: any) => {
        const options = res?.activity_kinds?.map((option: any) => ({
          value: option?.id,
          label: option?.name?.[i18n?.language]
        }));
        setActivityOption(options);
      });
  });

  //Get Legal entity
  const { data } = useQuery(
    [
      'GET_LEGAL_ENTITY_SYSTEM',
      currentPage,
      name,
      selectedActivityId,
      // selectedIsCustomValue,
      selectedIsBlockedValue
    ],
    async () => {
      const res = await legalEntityService
        .getListSystem({
          offset: pageToOffset(currentPage, 10),
          limit: 10,
          name: name,
          activity_kind_id: selectedActivityId,
          // is_custom: selectedIsCustomValue,
          is_blocked: selectedIsBlockedValue
        })
        .then((res: any) => res);
      return res;
    },
    {
      enabled:
        !!currentPage ||
        !!name ||
        !!selectedActivityId ||
        // !!selectedIsCustomValue ||
        !!selectedIsBlockedValue
    }
  );

  useEffect(() => {
    if (data) {
      setPageCount(Math.ceil(data?.count / 10));
    }
  }, [data]);

  const legalData: LegalEntityType[] = useMemo(() => {
    return (
      data?.legal_entities?.map((items: any) => {
        return {
          physical_name: items?.name,
          address: items?.address ?? '',
          kinds: items?.activity_kind?.name?.[i18n?.language] ?? '',
          country: items?.country?.name?.[i18n?.language] ?? '',
          area: items?.district?.name?.[i18n?.language] ?? '',
          region: items?.region?.name?.[i18n?.language] ?? '',
          custom: items?.is_custom === true ? t('yes') : t('no'),
          block: items?.is_blocked === true ? true : false,
          next_payment_date: items?.next_payment_date,
          guid: items?.id
        };
      }) ?? []
    );
  }, [data, t, i18n?.language]);

  const getFlatpickrLocale = () => {
    switch (i18n.language) {
      case 'ru':
        return Russian;
      case 'uz':
        return UzbekLatin;
      default:
        return undefined;
    }
  };

  const legalEntityTableColumns: ColumnDef<LegalEntityType>[] = [
    {
      accessorKey: 'physical_name',
      header: t('name_legal_entity'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'kinds',
      header: t('activity_type'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'country',
      header: t('country'),
      meta: {
        cellProps: {
          className: 'text-center',
          style: { whiteSpace: 'nowrap' }
        }
      }
    },

    {
      accessorKey: 'region',
      header: t('region'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'area',
      header: t('area'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'address',
      header: t('address'),
      meta: {
        cellProps: { className: 'text-900', style: { whiteSpace: 'nowrap' } }
      }
    },
    {
      accessorKey: 'custom',
      header: t('add_individually'),
      meta: {
        cellProps: { className: 'text-900', style: { whiteSpace: 'nowrap' } }
      }
    },
    {
      accessorKey: 'block',
      header: t('blocked'),
      cell: rowData => {
        const amount: any = rowData?.row?.original?.block;
        const [value, setValue] = useState(amount);

        const handleCheckboxChange = (
          e: React.ChangeEvent<HTMLInputElement>,
          id: any
        ) => {
          const checked = e.target.checked;
          setValue(checked);

          legalEntityService?.updateLegalEntity(id, { is_blocked: checked });
        };
        return (
          <div>
            <Form.Group className="printing-check">
              <Form.Check
                type="switch"
                checked={value}
                onChange={e =>
                  handleCheckboxChange(e, rowData.row?.original?.guid)
                }
                className="cursor-pointer"
              />
            </Form.Group>
          </div>
        );
      },
      meta: {
        cellProps: { className: 'text-900', style: { whiteSpace: 'nowrap' } }
      }
    },
    {
      accessorKey: 'next_payment_date',
      header: t('next_payment_date'),
      cell: rowData => {
        const nextPaymentDayMs: any | null =
          rowData?.row?.original?.next_payment_date;
        const id = rowData?.row?.original?.guid;

        // Parse the initial value from the table data (milliseconds to Date)
        const [selectedDate, setSelectedDate] = useState<any>(
          nextPaymentDayMs ? new Date(nextPaymentDayMs) : null
        );

        const handleStartDateChange = (date: Date | Date[]) => {
          let selected: Date | null = null;

          if (Array.isArray(date)) {
            selected = new Date(date[0]);
          } else {
            selected = new Date(date);
          }

          // Set time to 00:00 for consistency and convert to milliseconds
          if (selected) {
            selected.setHours(0, 0, 0, 0);
            const selectedMillis = selected.getTime();
            setSelectedDate(selected);

            // Send the updated value in milliseconds to the backend
            legalEntityService.updateLegalEntity(id, {
              next_payment_date: selectedMillis
            });
          }
        };

        return (
          <div>
            <Form.Group className="md-4">
              <Form.Floating>
                <Flatpickr
                  className={classNames('form-control')}
                  options={{
                    nextArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>`,
                    prevArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z"/></svg>`,
                    locale: getFlatpickrLocale(),
                    monthSelectorType: 'static',
                    onDayCreate: (...[, , , dayElem]) => {
                      if (
                        dayElem.dateObj.getDay() === 5 ||
                        dayElem.dateObj.getDay() === 6
                      ) {
                        dayElem.className += ' weekend-days';
                      }
                    },
                    dateFormat: 'M j, Y',
                    disableMobile: true
                  }}
                  value={selectedDate} // Use the current state as the value
                  onChange={handleStartDateChange} // Update the state and send the request
                  placeholder={t('next_payment_date')}
                />
                <label htmlFor="floatingInputCustom">
                  {t('next_payment_date')}
                </label>
              </Form.Floating>
            </Form.Group>
          </div>
        );
      },
      meta: {
        cellProps: { className: 'text-900', style: { whiteSpace: 'nowrap' } }
      }
    }
  ];

  const table = useAdvanceTable({
    data: legalData,
    columns: legalEntityTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  return (
    <div>
      <PageBreadcrumb items={legalEntityBreadcrumbItems} />
      <div className="mb-9">
        <h2 className="mb-5">{t('legal_entities')}</h2>

        <div className="d-flex gap-2 mb-3">
          <SearchBox
            placeholder={t('search')}
            onChange={e => setName(e?.target?.value)}
          />

          <Form.Group>
            <div className="react-select-container">
              <Form.Floating>
                <Form.Select
                  onChange={(e: any) => {
                    const selectedOption = activityOption.find(
                      (option: any) => option.value === e.target.value
                    );
                    if (selectedOption) {
                      setSelectedActivityId(selectedOption?.value);
                    }
                  }}
                >
                  <option className="d-none" value=""></option>
                  {activityOption?.map((option: any) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Form.Select>

                <label htmlFor="floatingInputCustom">
                  {t('activity_type')}
                </label>
              </Form.Floating>
            </div>
          </Form.Group>

          {/* <Form.Group>
            <div className="react-select-container">
              <Form.Floating>
                <Form.Select
                  style={{ width: '120px' }}
                  onChange={(e: any) => {
                    const selectedOption = customOption.find(
                      (option: any) => option.value === +e.target.value
                    );

                    if (selectedOption) {
                      setSelectedIsCustomValue(selectedOption?.value);
                    }
                  }}
                >
                  <option className="d-none" value=""></option>
                  {customOption?.map((option: any) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Form.Select>

                <label htmlFor="floatingInputCustom">
                  {t('add_individually')}
                </label>
              </Form.Floating>
            </div>
          </Form.Group> */}

          <Form.Group>
            <div className="react-select-container">
              <Form.Floating>
                <Form.Select
                  style={{ width: '120px' }}
                  onChange={(e: any) => {
                    const selectedOption = customOption.find(
                      (option: any) => option.value === +e.target.value
                    );
                    if (selectedOption) {
                      setSelectedIsBlockedValue(selectedOption?.value);
                    }
                  }}
                >
                  <option className="d-none" value=""></option>
                  {customOption?.map((option: any) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Form.Select>

                <label htmlFor="floatingInputCustom">{t('blocked')}</label>
              </Form.Floating>
            </div>
          </Form.Group>
        </div>

        <AdvanceTableProvider {...table}>
          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
            <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
            <AdvanceTableCPagination
              count={pageCount}
              page={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default LegalEntity;
