/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import { useQuery } from 'react-query';
import categoryService from 'services/category';

import './nomenclature.scss';

interface CustomSelectDropDownProps {
  setSelectedCategoryData?: any;
  selectedCategoryData?: any;
}

const LoadingSpinner = () => (
  <div className="loading-spinner">
    <div className="spinner"></div>
  </div>
);

const CustomSelectDropDown = ({
  setSelectedCategoryData,
  selectedCategoryData
}: CustomSelectDropDownProps) => {
  const { t, i18n } = useTranslation();

  //GET Category to options
  const {
    data: categoryData,
    isLoading,
    isFetching
  } = useQuery(['GET_CATEGORY'], async () => {
    const res = await categoryService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => res?.categories);

    return res;
  });

  const buildMenu = (data: any, parentId = undefined) => {
    const menu: any[] = [];
    data.forEach((item: any) => {
      if (item?.parent_id === parentId) {
        const children = buildMenu(data, item?.id);
        const menuItem = {
          id: item?.id,
          label: item?.name?.[i18n?.language] || '', // assuming you want the Russian names
          submenu: children?.length > 0 ? children : undefined,
          showSubmenu: false
        };

        menu.push(menuItem);
      }
    });

    return menu;
  };

  const [menuData, setMenuData] = useState<any[]>([]);
  const [filteredMenuData, setFilteredMenuData] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    if (categoryData) {
      const initialMenuData = buildMenu(categoryData);
      setMenuData(initialMenuData);
      setFilteredMenuData(initialMenuData); // Initialize with full menu data
    }
  }, [categoryData]);

  const toggleSubMenu = (e: any, categoryId: any) => {
    e.stopPropagation();

    const submenu = e.target.querySelector('ul');

    if (!submenu) return;

    if (submenu.style.display === 'none' || !submenu.style.display) {
      submenu.style.display = 'block';
    } else {
      submenu.style.display = 'none';
    }

    const updateShowSubmenuRecursively = (items: any[]) => {
      return items.map(item => {
        if (item.id === categoryId && item.submenu) {
          item.showSubmenu = !item.showSubmenu; // Toggle showSubmenu state
        }
        if (item.submenu) {
          item.submenu = updateShowSubmenuRecursively(item.submenu);
        }
        return item;
      });
    };

    const updatedMenuData = updateShowSubmenuRecursively(menuData);
    setMenuData(updatedMenuData);

    // Sync the filteredMenuData with updated menuData
    const filteredData = filterMenuData(searchQuery, updatedMenuData);
    setFilteredMenuData(filteredData);
  };

  const handleCheckboxChange = (id: any) => {
    const updateMenuDataRecursively = (menuData: any[]) => {
      return menuData.map(item => {
        if (item.id === id) {
          item.checked = true;
        } else {
          item.checked = false;
        }
        if (item.submenu) {
          item.submenu = updateMenuDataRecursively(item.submenu);
        }
        return item;
      });
    };
    const updatedMenuData = updateMenuDataRecursively(menuData);
    setMenuData(updatedMenuData);

    const findSelectedItem = (items: any[]): any => {
      for (let i = 0; i < items.length; i++) {
        if (items[i].id === id) {
          return items[i];
        }
        if (items[i].submenu) {
          const foundItem = findSelectedItem(items[i].submenu);
          if (foundItem) return foundItem;
        }
      }
      return null;
    };

    const selectedCategory = findSelectedItem(menuData);
    if (selectedCategory) {
      const { id, label } = selectedCategory;
      const selectedObject = { id, label };
      setSelectedCategoryData(selectedObject);
    }
  };

  const renderSubMenu = (subMenu: any) => {
    return (
      <ul className="submenu">
        {subMenu.map((subItem: any, index: any) => (
          <li key={index} onClick={e => toggleSubMenu(e, subItem.id)}>
            {subItem.label}
            {subItem.submenu ? (
              <>
                {!subItem?.showSubmenu && (
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    transform={{ size: 14 }}
                    style={{ marginLeft: '5px' }}
                  />
                )}
                {subItem?.showSubmenu && (
                  <FontAwesomeIcon
                    icon={faChevronUp}
                    transform={{ size: 14 }}
                    style={{ marginLeft: '5px' }}
                  />
                )}
                {renderSubMenu(subItem.submenu)}
              </>
            ) : (
              <LastItemCheckbox
                id={subItem.id}
                checked={subItem.checked}
                handleCheckboxChange={handleCheckboxChange}
              />
            )}
          </li>
        ))}
      </ul>
    );
  };

  // Filter the menu data based on search query
  const filterMenuData = (query: string, data = menuData) => {
    if (!query) {
      return data; // Return full menu if no search query
    }

    const filterRecursively = (items: any[]) => {
      return items
        .map(item => {
          const matches = item.label
            .toLowerCase()
            .includes(query.toLowerCase());

          // Recursively filter submenu items
          const filteredChildren = item.submenu
            ? filterRecursively(item.submenu)
            : [];

          // If parent matches OR any of its children matches, include the parent with all its children
          if (matches || filteredChildren.length > 0) {
            return {
              ...item,
              submenu: item.submenu // Include all children
            };
          }
          return null;
        })
        .filter(Boolean); // Remove null values
    };

    return filterRecursively(data);
  };

  const handleSearchChange = (e: any) => {
    const query = e.target.value;
    setSearchQuery(query);

    const filteredData = filterMenuData(query, menuData); // Pass the original menuData
    setFilteredMenuData(filteredData);
  };

  useEffect(() => {
    if (selectedCategoryData && menuData.length > 0) {
      const updatedMenuData = updateSelectedCategory(
        menuData,
        selectedCategoryData.id
      );
      setMenuData(updatedMenuData);

      // Filtered menu needs to be synced if there's a search query
      const filteredData = filterMenuData(searchQuery, updatedMenuData);
      setFilteredMenuData(filteredData);
    }
  }, [selectedCategoryData, menuData, searchQuery]);

  // Utility function to mark the selected category as checked
  const updateSelectedCategory = (data: any[], selectedId: any): any[] => {
    return data.map(item => {
      // If the item matches the selectedCategoryData, mark it as checked
      if (item.id === selectedId) {
        item.checked = true;
      } else {
        item.checked = false;
      }

      // Recursively update submenu
      if (item.submenu) {
        item.submenu = updateSelectedCategory(item.submenu, selectedId);
      }

      return item;
    });
  };

  return (
    <div className="main-container">
      <div className="fixed-input-container">
        <input
          type="text"
          className="search-input"
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder={t('search')}
        />
      </div>
      {isLoading || isFetching ? (
        <LoadingSpinner />
      ) : (
        <ul className="main-cat">
          {filteredMenuData.map((item, index) => (
            <li key={index} onClick={e => toggleSubMenu(e, item.id)}>
              {item.label}
              {item.submenu ? (
                <>
                  <FontAwesomeIcon
                    icon={item.showSubmenu ? faChevronUp : faChevronDown}
                    transform={{ size: 14 }}
                    style={{ marginLeft: '5px' }}
                  />
                  {renderSubMenu(item.submenu)}
                </>
              ) : (
                // Add a checkbox for parent items without children
                <LastItemCheckbox
                  id={item.id}
                  checked={item.checked}
                  handleCheckboxChange={handleCheckboxChange}
                />
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const LastItemCheckbox = ({
  id,
  checked,
  handleCheckboxChange
}: {
  id: any;
  checked: boolean;
  handleCheckboxChange: (id: any) => void;
}) => {
  const handleCheckboxChangeLocal = () => {
    handleCheckboxChange(id);
  };

  return (
    <input
      className="form-check-input"
      type="checkbox"
      checked={checked}
      onChange={handleCheckboxChangeLocal}
      style={{ marginLeft: '10px', cursor: 'pointer' }}
    />
  );
};

export default CustomSelectDropDown;
